import { useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { formatNumberWithFraction } from "../../../utils/helper";


import moment from "moment";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function BarChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
}) {

  console.log("data", data);
  
  const [themeMode, setTheme] = useContext(ThemeContext);

    const formattedData = data?.graphData?.map((data) => ({
      x: moment(data.payPeriodEndDate, "MM/DD/YYYY").format("MMM YYYY"), // For X-axis display
      originalDate: data.payPeriodEndDate, // Store original date for tooltip
      y: data.cost_center_count, // Y-axis value
    }));


  var options = {
    chart: {
      height: 350,
      type: "bar",
      stacked: true,
      color: themeMode.theme === "DARK" ? "white" : "black",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },

    colors: ["#6ca9d6"],

    stroke: {
      curve: "smooth",
      width: [2, 2],
    },
    markers: {
      size: 6,
      strokeColors: "#fff",
      hover: {
        size: 6,
        sizeOffset: 3,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "40%",
        barHeight: "40%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      // hideOverflowingLabels: true,
      formatter: (val) => (val === 0 ? 0 : val),
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
        padding: {
          top: 30,
        },
      },
    },
    xaxis: {
      categories: data?.monthResponse?.displayMonths,
      tickAmount: data?.monthResponse?.displayMonths?.length,
      type: "categories",
      title: {
        show: false,
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
        show: true,
      },
      tickPlacement: "between",
    },
    yaxis: {
      show: true,
      // tickAmount: 5,
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: (value, { dataPointIndex }) => {
          return formattedData[dataPointIndex]?.originalDate || value;
        },
      },
      y: {
        formatter: (value) => formatNumberWithFraction(value, 0), // Tooltip for Y-axis
      },
    },
    legend: {
      labels: {
        colors: themeMode.theme === "DARK" ? "white" : "black",
        useSeriesColors: false,
      },
      horizontalAlign: "center",
      position: "top",
      offsetY: 20,
      offsetX: 0,
    },

    // annotations: {
    //   position: "back",
    //   yaxis: [
    //     {
    //       y: data?.averageCostCenters,
    //       strokeDashArray: 8,
    //       borderWidth: 1,
    //       borderColor: themeMode.theme === "DARK" ? "#e0d61e90" : "#e0d61e90",

    //       label: {
    //         borderColor: "#6ca9d6",
    //         textAnchor: "start",
    //         position: "left",
    //         style: {
    //           color: "#000",
    //           background: "#6ca9d6",
    //         },
    //         text: `Average: ${
    //           data?.averageCostCenters
    //             ? formatNumberWithFraction(data?.averageCostCenters, 1)
    //             : "0"
    //         }`,
    //       },
    //     },
    //   ],
    // },
  };
  
  return (
    <div>
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2">
          <div
            class="f-14 text-center mb-minus-10 font-w-500"
            style={{ color: "themeMode.theme === 'DARK' ? 'white' : 'black'," }}
          >
            {title}
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <Chart
                options={options}
                series={[
                  {
                    name: "Cost Center",
                    data: formattedData?.map((item) => item.y),
                  },
                ]}
                type="bar"
                //  width={"100%"}
                // height={135}
                height={295}
              />
            </div>
          </div>
        </div>
      ) : (
        <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default BarChart;
