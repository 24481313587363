import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/shared/InfoModal";
import UosModal from "./UosModal";
import BudgetModal from "./BudgetModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import {
  getTableRecordWithSorting,
  getDateRecordWithSorting,
} from "../../../services";
import moment from "moment";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import BiArrowDown from "../../../assets/img/biarrowdown.png";
import BiArrowUp from "../../../assets/img/biarrowup.png";
import BiClose from "../../../assets/img/biclose.png";
import BiDash from "../../../assets/img/bidash.png";
import BiDone from "../../../assets/img/bidone.png";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import ADMImage from "../../../assets/img/biweekly_report.png";
import Button from "@atlaskit/button";

import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 45, height: 30 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="200" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

function CostCenterScorecard({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const { department } = useParams();
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [uosmodalShow, setUosModalShow] = React.useState(false);
  const [budgetmodalShow, setBudgetModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);
  const [allCostCenter, setAllCostCenter] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [yearDateList, setYearDateList] = useState([]);
  const [uosBudget, setUosBudget] = useState(null);

  const getTableData = async (eCost, eYear) => {
    setIsTableLoading(true);
    const parData = {
      cost_center_number: eCost,
      fiscal_year: eYear,
    };
    parData.page = page;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/costCenterScorecard/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      console.log("bnn", data);

      setIsTableLoading(false);
      setTableHeaderData(data?.data?.header);
      setTableData(data?.data?.result);
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };


  // console.log("tableDat", tab);
  

  const getHeadData = async () => {
    setIsLoading(true);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getheaderData`
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCostCenterList = async (ccn) => {
     setIsTableLoading(true);
    try {
     const paramData = {
       businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
     };
     const encryptData = dataEnCrypt(paramData);
     const response = await axios.post(
       `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/costCentersList`,
       { payloadData: encryptData }
     );

     const data = decryptedFunction(response?.data?.responseData);

     setCostCenterList(
       data?.data?.costCenterResponse?.map((item) => {
         return {
           label: `${item.cost_center_number} - ${capitalizeAllLetters(
             item.cost_center_name
           )}`,
           value: item.cost_center_number,
         };
       })
     );

      setAllCostCenter(data?.data?.costCenterResponse);

     const matchingCostCenter = data.data?.costCenterResponse.filter(
       (val) => val.cost_center_slug === department
     )?.[0];

      if (!matchingCostCenter) {
        history(`/activedailymanagement/not-found`);
      }
      setDropdownValue({
        label: `${
          matchingCostCenter?.cost_center_number
        } - ${capitalizeAllLetters(matchingCostCenter?.cost_center_name)}`,
        value: matchingCostCenter?.cost_center_number,
      });
      return matchingCostCenter;
    } catch (err) {
      console.log(err);
    }
  };

  const jumpFun = (e) => {
    let filVal = allCostCenter.filter((val) => val.cost_center_number === e);
    console.log("filVal", filVal);
    
    const url = `/biweekly/costcenterscorecard/${filVal[0]?.cost_center_slug}`;
    if (filVal[0]?.cost_center_slug) {
      history(url);
    } else {
      return "";
    }
  };




   const ppfiscalYearList = async () => {
     try {
       const parData = {
         cost_center_number: 100061102,
       };
       const encryptData = dataEnCrypt(parData);
       const response = await axios.post(
         `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/ppfiscalYearList`,
         { payloadData: encryptData }
       );

       console.log("response", response);
       

       const data = decryptedFunction(response?.data);

       setYearDateList(
         data.data?.map((val) => {
           return {
             label: val,
             value: val,
           };
         })
       );

        setDropdownValueThree({
          label: data?.data[0],
          value: data?.data[0],
        });
       

       return data?.data[0] || "" ;
     } catch (error) {
       console.error("Failed to fetch user data:", error);
     } finally {
       setIsLoading(false);
     }
   };

  useEffect(() => {
    // getTableData();
    // getCostCenterList();
    fetchData();
    getRefreshSchedule();
    getBuisnesUnit();
    // ppfiscalYearList();
    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {
    console.log("key", key);
    

    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = key === "end_date" ? 
    getDateRecordWithSorting(dataToSort, key, sortType) :
    getTableRecordWithSorting(dataToSort, key, sortType);
    console.log("sortdata", sortdata);
    
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);


    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const emptyActiveData = () => {
    getTableData();
    getHeadData();
    setSelectedRow(null);
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setUpdateStack(""));
  };

  const handleDoubleExceptional = (val) => {
    navigate(`/activedailymanagement/adm-summary/${val?.cost_center_slug}`);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 103,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/metaData`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Biweekly Reporting";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/getFooterData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };


  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };


  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }

  }, [isBuisnessUnitSet]);


  // useEffect(() => {
  //   if (mounted) {
  //     fetchDataRecursive();
  //   } else {
  //     setMounted(true);
  //   }
  // }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 103,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

    const toggleOpen = () => {
      setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
    };

    const toggleOpenTwo = () => {
      setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
    };

    useEffect(() => {}, [isDropdownOpen, dropdownValue]);

    const formatDateToDDMMYYYY = (dateString) => {
      return moment(dateString, "DD-MM-YYYY")?.format("MM/DD/YYYY");
    };



    const fetchData = async (ccn) => {
      try {
        const Costlist = await getCostCenterList(ccn);
        const Yearlist = await ppfiscalYearList();
        await getTableData(Costlist?.cost_center_number, Yearlist);
        setIsLoading(false);
        setIsTableLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const getVarianceIconColor = (value) => {
      if (value > 0 && value < 1000000) {
        return BiDone;
      } else if (value > -1000000 && value <= 0) {
        return BiClose;
      }
    };

    const getPerfomanceColor = (value) => {
       if (value >= 0 && value <= 98) {
         return BiClose;
       } else if (value > 98 && value <= 100) {
         return BiDash;
       } else if (value > 100 && value <= 200) {
         return BiDone;
       }
    };

console.log("uosBu", uosBudget);


  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        {/* <Row className="gx-2 mt-0 align-items-center">
          <Col className="d-flex " md={{ span: 2 }}>
            
          </Col>
          <Col md={{ span: 8 }}>
            <Row className="mt-2 justify-content-center">
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
            </Row>
          </Col>
         

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row> */}

        <Row className="gx-2  mt-2 mb-2 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/biweekly`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 10 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              <div>
                <h3 className="text-center color-black m-0">
                  Cost Center Scorecard -
                </h3>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : ""}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      jumpFun(e?.value);
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueThree?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                  />
                </CustomDropdown>
              </div>

              {/* <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      08/10/2024
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={filterDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setDropdownValueTwo(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueTwo}
                  />
                </CustomDropdown>
              </div> */}

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      {dropdownValueThree ? dropdownValueThree.label : ""}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    options={yearDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenThree();
                      setDropdownValueThree(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueThree}
                  />
                </CustomDropdown>
              </div>
            </div>
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2"></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <UosModal show={uosmodalShow} onHide={() => setUosModalShow(false)} />
          <BudgetModal
            show={budgetmodalShow}
            onHide={() => setBudgetModalShow(false)}
          />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="mt-3 gx-2">
          <Col className="mobile-half">
            <div class="position-relative  h-100 box-h col-bg">
              <div class="d-flex align-items-center justify-content-between">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">Cost Center Type : </span>
                </div>
                {isTableLoading ? (
                  <LinkLoader themeMode={themeMode} />
                ) : (
                  <div class="ps-2">
                    <div class="pe-0 primary-font">
                      <span class="f-16 color-black fw-600 ">
                        {tableHeaderData?.cost_center_type}
                      </span>
                      &nbsp;
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col className="mobile-half">
            <div class="position-relative  h-100 box-h col-bg">
              <div class="d-flex align-items-center justify-content-between">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">Target Type : </span>
                </div>
                {isTableLoading ? (
                  <LinkLoader themeMode={themeMode} />
                ) : (
                  <div class="ps-2">
                    <div class="pe-0 primary-font">
                      <span class="f-16 color-black fw-600 ">
                        {tableHeaderData?.target_type}
                      </span>
                      &nbsp;
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col className="mobile-half">
            <div class="position-relative  h-100 box-h col-bg">
              <div class="d-flex align-items-center justify-content-between">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">
                    Units of Service Description :{" "}
                  </span>
                </div>
                {isTableLoading ? (
                  <LinkLoader themeMode={themeMode} />
                ) : (
                  <div class="ps-2">
                    <div class="pe-0 primary-font">
                      <span class="f-16 color-black fw-600 ">
                        {tableHeaderData?.business_unit_description || "N/A"}
                      </span>
                      &nbsp;
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col className="mobile-half">
            <div
              class="position-relative  h-100 box-h col-bg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setBudgetModalShow(true);
              }}
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">
                    {/* {uosBudget
                      ? `Budgeted UOS  ${formatDateToDDMMYYYY(
                          uosBudget?.end_date
                        )}
                      :`
                      : `Budgeted UOS Annual Avg Age :`}{" "} */}
                    {`Budgeted UOS Annual Avg Age :`}
                  </span>
                </div>
                {isTableLoading ? (
                  <LinkLoader themeMode={themeMode} />
                ) : (
                  <div class="ps-2">
                    <div class="pe-0 primary-font">
                      <span class="f-16 color-black fw-600 ">
                        {/* {uosBudget
                          ? formatNumberWithFraction(
                              uosBudget?.uos_volume_nbr_avg,
                              0
                            )
                          : formatNumberWithFraction(
                              tableHeaderData?.uos_volume_nbr_avg,
                              0
                            )} */}
                        {formatNumberWithFraction(
                          tableHeaderData?.uos_volume_nbr_avg,
                          0
                        )}
                      </span>
                      &nbsp;
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="gx-2">
          <Col md={12} className="mt-2 h-6100">
            <div className="bg-white p-3 h-100">
              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table first-t  min-w-1400 costcenterscorecardtable"
              >
                <thead className="all-side-border">
                  <tr className="vertical-align-middle">
                    <th className="border-0"></th>
                    <th className="border-0"></th>
                    <th className="border-0"></th>
                    <th colSpan={8}>Worked</th>
                    <th colSpan={4}>Paid</th>
                    <th colSpan={3} className="border-0"></th>
                  </tr>
                  <tr className="vertical-align-middle">
                    <th className="border-0"></th>
                    <th className="border-0"></th>
                    <th>Volume</th>
                    <th colSpan={3}>Direct Productive</th>
                    <th colSpan={2}>Indirect Productive</th>
                    <th colSpan={3}>Total Productive (Direct + Indirect)</th>
                    <th>Non Productive</th>
                    <th colSpan={3}>Total Productive (Direct + Indirect)</th>
                    <th colSpan={3}>HPUOS Variance</th>
                  </tr>
                  <tr className="vertical-align-middle w-93">
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("fiscal_year_name")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Pay
                          <br />
                          Period
                          <br />
                          Name
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "fiscal_year_name" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("end_date")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>End Date</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "end_date" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("uos")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>UOS</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "uos" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("regular_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Regular
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "regular_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("overtime_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Overtime
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "overtime_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("agency_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Agency
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "agency_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("edu_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          EDU
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "edu_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("orient_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Orient
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "orient_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("worked_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Worked
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "worked_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("target_worked_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Target
                          <br />
                          Worked
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "target_worked_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("worked_ftes_variance")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Worked FTEs
                          <br />
                          Variance From
                          <br />
                          Target
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "worked_ftes_variance" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("non_productive_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Non-
                          <br />
                          Productive
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "non_productive_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("paid_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Paid
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "paid_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("target_paid_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Target Paid
                          <br />
                          FTEs
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "target_paid_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("paid_ftes_variance")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Paid FTEs
                          <br />
                          Varience From
                          <br />
                          Target
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "paid_ftes_variance" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("hpuos")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>HPUOS</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "hpuos" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("target")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Target</div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "target" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("performance")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Performance
                          <br />
                          Percentage
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" && sortName === "performance" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="ftr all-new-border">
                  {!isTableLoading ? (
                    tableData?.length > 0 ? (
                      tableData?.map((val, ind) => {
                        return (
                          <tr
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setUosModalShow(true);
                            }}
                          >
                            <td className="text-center">
                              {val?.fiscal_year_name}
                            </td>
                            <td className="text-center">
                              {formatDateToDDMMYYYY(val?.end_date)}
                            </td>
                            <td
                              className="text-center"
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setUosBudget(val);
                                  // setModalShow(true);
                                  // setUosModalShow(true);
                                }}
                              >
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip
                                      id="button-tooltip"
                                      className="custom-tooltip"
                                    >
                                      Budgeted UOS: {val?.uos_volume_nbr_avg}
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {formatNumberWithFraction(val?.uos, 0)}
                                    &nbsp;&nbsp;
                                    <span>
                                      <img
                                        src={
                                          val?.uos_arrow
                                            ? BiArrowUp
                                            : BiArrowDown
                                        }
                                        alt=""
                                      />
                                    </span>
                                    &nbsp;
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.regular_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {formatNumberWithFraction(val?.overtime_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {" "}
                              {formatNumberWithFraction(val?.agency_ftes, 1)}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.edu_ftes, 1)}
                            </td>
                            <td className=" text-center">
                              {" "}
                              {formatNumberWithFraction(val?.orient_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {formatNumberWithFraction(val?.worked_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {formatNumberWithFraction(
                                val?.target_worked_ftes,
                                1
                              )}
                            </td>
                            <td
                              className="text-center"
                              style={{
                                color:
                                  val?.worked_ftes_variance > 0 &&
                                  val?.worked_ftes_variance < 1000000
                                    ? "green"
                                    : val?.worked_ftes_variance > -1000000 &&
                                      val?.worked_ftes_variance <= 0
                                    ? "red"
                                    : "red",
                              }}
                            >
                              {formatNumberWithFraction(
                                val?.worked_ftes_variance,
                                1
                              )}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(
                                val?.non_productive_ftes,
                                1
                              )}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.paid_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {" "}
                              {formatNumberWithFraction(
                                val?.target_paid_ftes,
                                1
                              )}
                            </td>
                            <td
                              className="text-center"
                              style={{
                                color:
                                  val?.paid_ftes_variance > 0 &&
                                  val?.paid_ftes_variance < 1000000
                                    ? "green"
                                    : val?.paid_ftes_variance > -1000000 &&
                                      val?.paid_ftes_variance <= 0
                                    ? "red"
                                    : "red",
                              }}
                            >
                              {formatNumberWithFraction(
                                val?.paid_ftes_variance,
                                1
                              )}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.hpuos, 3)}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.target, 3)}
                            </td>
                            <td
                              className="text-center"
                              style={{
                                color:
                                  val?.performance >= 100
                                    ? "green"
                                    : val?.performance >= 98 &&
                                      val?.performance < 100
                                    ? "yellow"
                                    : "red",
                              }}
                            >
                              {formatNumberWithFraction(val?.performance, 1)}%
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  // onClick={() => {
                  //   getRefreshSchedule(true);
                  //   setFooterShow(true);
                  // }}
                >
                  {scheduleData?.msg1}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default CostCenterScorecard;
