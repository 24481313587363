import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal1 from "../../../components/shared/BiweeklyVarianceChart/InfoModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import BiweeklyLineChart from "../../../components/shared/BiweeklyVarianceChart/index";
import BiweeklyLineChart2 from "../../../components/shared/BiweeklyVarianceChart/index2";
import DateFilter from "../../../components/shared/BiweeklyVarianceChart/dateFilter";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../../services";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import LineChart from "../../../assets/img/grap-line.jpg";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import Button from "@atlaskit/button";


const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

function VarianceTrends({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [yearDateList, setYearDateList] = useState([]);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);
  const [isDropdownOpenfour, setIsDropdownOpenFour] = useState(false);
  const [dropdownValueFour, setDropdownValueFour] = useState(undefined);
  const [sliderFilterData, setSliderFilterData] = useState({});
  const [activefiltertab, setActivefiltertab] = useState("undefined");
  const [filterdate, setFilterdate] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [payperiodDateList, setPayperiodDateList] = useState([]);
  const [varianceTrendType, setVarianceTrendType] = useState("Current");

  const getTableData = async () => {
    setIsTableLoading(true);
    const parData = {};
    parData.page = page;
    // parData.limit = 25;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setIsTableLoading(false);
      setTableData(data?.data?.cost_centers);

      if (data?.data?.hasNextPage) {
        getTableNextData(data?.data?.nextPage);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const getTableNextData = async (pageIn) => {
    const parData = {};
    // parData.limit = 500;
    parData.page = pageIn;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setTableData((prevData) => [
        ...prevData,
        ...(data?.data?.cost_centers || []),
      ]);

      if (data?.data?.hasNextPage) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // setTimeout(() => {
        getTableNextData(data?.data?.nextPage);
        // }, 1000);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const fetchDataRecursive = async (
    currentPage = page,
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    // try {
    if (initialCall) {
      setIsTableLoading(true);
    }

    const parData = {
      page: currentPage,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };

    if (searchKey) {
      parData.searchKeyword = searchKey;
    }

    if (limit) {
      parData.limit = limit;
    }

    if (buisnessCategory) {
      parData.businessUnit = buisnessCategory;
    }

    // else {
    //   parData.businessUnit = "";
    // }
    const encryptData = dataEnCrypt(parData);
    const response = await axios.post(
      `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      { payloadData: encryptData },
      { cancelToken: cancelToken }
    );
    const responseData = decryptedFunction(response?.data?.responseData);
    const costCenters = responseData?.data?.cost_centers || [];

    if (searchKey || buisnessCategory || isBuisnessUnitSet !== null) {
      setTableData(costCenters);
    } else {
      setTableData((prevData) => [...prevData, ...costCenters]);
    }

    if (initialCall) {
      setIsTableLoading(false);
    }

    if (response?.data?.data?.hasNextPage) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await fetchDataRecursive(
        response?.data?.data?.nextPage,
        false,
        null,
        null,
        null,
        buisnessCategory
      );
    }
    // } catch (error) {
    //   setIsTableLoading(false);
    //   if (axios.isCancel(error)) {
    //     console.log("Request canceled:", error.message);
    //   } else {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  const getHeadData = async () => {
    setIsLoading(true);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getheaderData`
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCostCenterList = async (ccn) => {
    try {
        const paramData = {
          businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
        };
        const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/costCentersList`,
          { payloadData: encryptData }
        );
      const data = decryptedFunction(response?.data?.responseData);

      setCostCenterList(
        data?.data?.costCenterResponse.map((item) => {
          return {
            label: `${item.cost_center_number} - ${capitalizeAllLetters(
              item.cost_center_name
            )}`,
            value: item.cost_center_number,
          };
        })
      );

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      return;
    }
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    setClickCount(0);
    getMetaData();
    fetchDataRecursive();
    getHeadData();
    // getCostCenterList();
    getRefreshSchedule();
    getBuisnesUnit();
    getCostCenterList();
    ppfiscalYearList();
    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {

    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);


    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 103,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/metaData`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Biweekly Reporting";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };


  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }

  }, [isBuisnessUnitSet]);


  useEffect(() => {
    if (mounted) {
      fetchDataRecursive();
    } else {
      setMounted(true);
    }
  }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 105,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };


      const toggleOpen = () => {
        setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenTwo = () => {
        setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenFour = () => {
        setIsDropdownOpenFour((prevIsOpen) => !prevIsOpen);
      };


   const ppfiscalYearList = async () => {
     try {
       const response = await axios.post(
         `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/ppfiscalYearList`
       );

       const data = decryptedFunction(response?.data);

       setYearDateList(
         data.data?.map((val) => {
           return {
             label: val,
             value: val,
           };
         })
       );

       setDropdownValueThree({
         label: data?.data[0],
         value: data?.data[0],
       });

       if (data?.data) {
         ppfiscalDateList(data?.data[0]);
       }

       return data?.data[0] || "";
     } catch (error) {
       console.error("Failed to fetch user data:", error);
     } finally {
       setIsLoading(false);
     }
   };


    const ppfiscalDateList = async (ppYear, isParam) => {
      try {
        const parData = {
          fiscal_year: ppYear,
        };
        const encryptData = dataEnCrypt(parData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/fiscalYearPayPeriodDates`,
          { payloadData: encryptData }
        );
        console.log("yut", response);

        const data = decryptedFunction(
          response?.data?.responseData?.data?.payPeriodEndDates
        );
        console.log("mip", data);

        setPayperiodDateList(
          data?.map((val) => {
            return {
              label: val?.display_date,
              value: val?.payload_date,
            };
          })
        );

        const startDate = data?.at(0);
        const endDate = data?.at(-1);

        setStartDateValue({
          label: startDate?.display_date,
          value: startDate?.payload_date,
        });

        setEndDateValue({
          label: endDate?.display_date,
          value: endDate?.payload_date,
        });


        return data?.data[0] || "";
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

     const isStartDateDisabled = (option) => {
       if (!endDateValue) return false;
       return (
         new Date(option.value.split("-").reverse().join("-")) >
         new Date(endDateValue.value.split("-").reverse().join("-"))
       );
     };

     const isEndDateDisabled = (option) => {
       if (!startDateValue) return false;
       return (
         new Date(option.value.split("-").reverse().join("-")) <
         new Date(startDateValue.value.split("-").reverse().join("-"))
       );
     };

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        <Row className="gx-2  mt-2 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/biweekly`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 8 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              {/* <div>
                <h3 className="text-center color-black m-0">
                  Biweekly Reporting -
                </h3>
              </div> */}

              <div
                className={`custom-select-v mobie-full ${
                  !isEmpty(dropdownValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : "Cost Center"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueTwo?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                  />
                </CustomDropdown>
                {!isEmpty(dropdownValue) && (
                  <span
                    onClick={() => {
                      setDropdownValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      {dropdownValueThree ? dropdownValueThree.label : "Year"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    options={yearDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenThree();
                      setDropdownValueThree(e);
                      // ppfiscalDateList(e?.value, true);
                    }}
                    value={dropdownValueThree}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      {startDateValue ? startDateValue.label : "Start Date"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={payperiodDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    isOptionDisabled={isStartDateDisabled}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setStartDateValue(e);
                    }}
                    value={startDateValue}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfour}
                  onClose={toggleOpenFour}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFour}
                      isSelected={isDropdownOpenfour}
                    >
                      {endDateValue ? endDateValue.label : "End Date"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfour}
                    options={payperiodDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    isOptionDisabled={isEndDateDisabled}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenFour();
                      setEndDateValue(e);
                    }}
                    value={endDateValue}
                  />
                </CustomDropdown>
              </div>
            </div>
          </Col>
          <Col md={3} className="text-end mobile-center mobile-my-2 d-flex bbd">
            <div
              className={`text-center text-mobile-center rounded w-50 ${
                varianceTrendType === "Current" ? "green-btn" : "border-btn"
              }`}
            >
              <span
                className="d-inline-block  cursor-pointer"
                onClick={() => {
                  // setModalShow(true);
                  setVarianceTrendType("Current");
                }}
              >
                Current
              </span>
            </div>
            <div
              className={`text-center text-mobile-center rounded w-50 ${
                varianceTrendType === "Rolling-4" ? "green-btn" : "border-btn"
              }`}
            >
              <span
                className="d-inline-block cursor-pointer"
                onClick={() => {
                  setVarianceTrendType("Rolling-4");
                }}
              >
                Rolling-4
              </span>
            </div>
            {/* {process.env.React_App_ENVIRONMENT === "local" &&  ( */}
            {/* <div className="text-center text-mobile-center green-btn rounded">
              <span
                className="d-inline-block   cursor-pointer"
                onClick={() => {
                  setModalShow(true);
                }}
              >
                Button
              </span>
            </div> */}
            {/* )} */}
          </Col>

          <InfoModal1 show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="gx-2 ">
          {/* <Col md={4} className="mt-2">
            <div className="bg-white p-2  text-center">
              <h4 class=" text-center mb-minus-10 font-w-500 mb-2">
                {varianceTrendType}
              </h4>
              <div>
                <Row className="gx-1 justify-content-center">
                  <Col md={4}>
                    <div class="p-2 text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        UOS
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22">
                        65
                      </h4>
                      <div class="f-14"></div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div class="text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        HPUOS
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22 text-danger">
                        22.543!
                      </h4>
                      <div class="f-14">Goal:19.100 (-18.03%)</div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div class="text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        Worked FTEs
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22 text-danger">
                        22.543!
                      </h4>
                      <div class="f-14">Goal:19.100 (-18.03%)</div>
                    </div>
                  </Col>

                  <Col md={12}>
                    <hr />
                  </Col>
                  <Col md={3}>
                    <div class="text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        Agency FTEs
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22">
                        25
                      </h4>
                      <div class="f-14"></div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div class=" text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        EDU FTEs
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22">
                        0.0
                      </h4>
                      <div class="f-14"></div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div class=" text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        Orient FTEs
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22">
                        0.0
                      </h4>
                      <div class="f-14"></div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div class="text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        Paid FTEs
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22 text-danger">
                        0.0
                      </h4>
                      <div class="f-14">Goal:1.0 (-18.03%)</div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <hr />
                  </Col>
                  <Col md={3}>
                    <div class=" text-center">
                      <h4 class="text-center justify-content-center mb-0 fs-16">
                        Variance
                      </h4>
                      <h4 class="text-center justify-content-center mb-0 fw-light f-22">
                        19.5
                      </h4>
                      <div class="f-14">&nbsp;</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col> */}
          <Col md={12} className="mt-4">
            <div className="bg-white p-3  text-center">
              <div class="f-16 text-center mb-minus-10 font-w-500 mb-2">
                All Cost Center : Performance Variance By Pay Period
              </div>
              {/* <img src={LineChart} alt="" style={{margin: "0 auto"}}/> */}
              <BiweeklyLineChart
                // title={"All Cost Center : Actual And Target Worked FTE Trends"}

                color={"#458FFF"}
              />
            </div>
          </Col>
        </Row>
        {/* <Row className="gx-2">
          <Col md={12} className="mt-2 ">
            <div className="bg-white p-3 h-100">
              <div className="overlfow-auto">
                <Table className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table min-w-1100 qus-ans-table">
                  <thead>
                    <tr className="vertical-align-middle">
                      <th className="text-center cursor-pointer">
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            PayPeriod
                            <br />
                            End Date
                          </div>
                        </div>
                      </th>
                      <th className="text-center cursor-pointer">
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            What obstacles Are preventing You From Meeting Your
                            Produvtive FTE Budget?
                          </div>
                        </div>
                      </th>

                      <th className="text-center cursor-pointer">
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            If You Subtract Your Known Variable Causing Your
                            Variance, What Is Your New Variance
                          </div>
                        </div>
                      </th>
                      <th className="text-center cursor-pointer">
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            WHich 1-2 obstacles Do You plan To Address Now?
                          </div>
                        </div>
                      </th>
                      <th className="text-center cursor-pointer">
                        <div className="d-flex justify-content-start align-items-center">
                          <div>How Do You Plan To Address It/Them?</div>
                        </div>
                      </th>
                      <th className="text-center cursor-pointer">
                        <div className="d-flex justify-content-center align-items-center">
                          <div>
                            WHat Do You Expect? By Taking Above Action(S),How
                            Will It Impact Your Productive FTE?
                          </div>
                        </div>
                      </th>
                      <th className="text-center cursor-pointer">
                        <div className="d-flex justify-content-center align-items-center">
                          <div>When Can We See What You Have Learned?</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1/15/2022</td>
                      <td>
                        Increased Acuity Of Patients. Multiple Patients On CRRT
                      </td>
                      <td>
                        For The Last 2 Weeks. If I Subtract The 4.5days Of
                        CRRT(1.35) Our New Variance Will Be 1.22
                      </td>
                      <td>
                        Nfortunately I Can't Control The Acuity Level Of The
                        Patient. Any Way We Could Charge More UOS For A 1 To 1
                        Patient? Orientation, 1 To 1 Ratio.
                      </td>
                      <td>
                        We Are Always In Discussion With The ICU MD And The
                        Nephrologist To See If The Patient Can Tolerate HD.
                      </td>
                      <td>
                        I Don't Expect Much Change. Unless We Could Charge More
                        For Patient That Require 1 To 1 Care.
                      </td>
                      <td>Hoping To See My Rolling</td>
                    </tr>
                    <tr>
                      <td>1/15/2022</td>
                      <td>
                        Increased Acuity Of Patients. Multiple Patients On CRRT
                      </td>
                      <td>
                        For The Last 2 Weeks. If I Subtract The 4.5days Of
                        CRRT(1.35) Our New Variance Will Be 1.22
                      </td>
                      <td>
                        Nfortunately I Can't Control The Acuity Level Of The
                        Patient. Any Way We Could Charge More UOS For A 1 To 1
                        Patient? Orientation, 1 To 1 Ratio.
                      </td>
                      <td>
                        We Are Always In Discussion With The ICU MD And The
                        Nephrologist To See If The Patient Can Tolerate HD.
                      </td>
                      <td>
                        I Don't Expect Much Change. Unless We Could Charge More
                        For Patient That Require 1 To 1 Care.
                      </td>
                      <td>Hoping To See My Rolling</td>
                    </tr>
                    <tr>
                      <td>1/15/2022</td>
                      <td>
                        Increased Acuity Of Patients. Multiple Patients On CRRT
                      </td>
                      <td>
                        For The Last 2 Weeks. If I Subtract The 4.5days Of
                        CRRT(1.35) Our New Variance Will Be 1.22
                      </td>
                      <td>
                        Nfortunately I Can't Control The Acuity Level Of The
                        Patient. Any Way We Could Charge More UOS For A 1 To 1
                        Patient? Orientation, 1 To 1 Ratio.
                      </td>
                      <td>
                        We Are Always In Discussion With The ICU MD And The
                        Nephrologist To See If The Patient Can Tolerate HD.
                      </td>
                      <td>
                        I Don't Expect Much Change. Unless We Could Charge More
                        For Patient That Require 1 To 1 Care.
                      </td>
                      <td>Hoping To See My Rolling</td>
                    </tr>
                    <tr>
                      <td>1/15/2022</td>
                      <td>
                        Increased Acuity Of Patients. Multiple Patients On CRRT
                      </td>
                      <td>
                        For The Last 2 Weeks. If I Subtract The 4.5days Of
                        CRRT(1.35) Our New Variance Will Be 1.22
                      </td>
                      <td>
                        Nfortunately I Can't Control The Acuity Level Of The
                        Patient. Any Way We Could Charge More UOS For A 1 To 1
                        Patient? Orientation, 1 To 1 Ratio.
                      </td>
                      <td>
                        We Are Always In Discussion With The ICU MD And The
                        Nephrologist To See If The Patient Can Tolerate HD.
                      </td>
                      <td>
                        I Don't Expect Much Change. Unless We Could Charge More
                        For Patient That Require 1 To 1 Care.
                      </td>
                      <td>Hoping To See My Rolling</td>
                    </tr>
                    <tr>
                      <td>1/15/2022</td>
                      <td>
                        Increased Acuity Of Patients. Multiple Patients On CRRT
                      </td>
                      <td>
                        For The Last 2 Weeks. If I Subtract The 4.5days Of
                        CRRT(1.35) Our New Variance Will Be 1.22
                      </td>
                      <td>
                        Nfortunately I Can't Control The Acuity Level Of The
                        Patient. Any Way We Could Charge More UOS For A 1 To 1
                        Patient? Orientation, 1 To 1 Ratio.
                      </td>
                      <td>
                        We Are Always In Discussion With The ICU MD And The
                        Nephrologist To See If The Patient Can Tolerate HD.
                      </td>
                      <td>
                        I Don't Expect Much Change. Unless We Could Charge More
                        For Patient That Require 1 To 1 Care.
                      </td>
                      <td>Hoping To See My Rolling</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row> */}
      </div>
    </PageLayout>
  );
}

export default VarianceTrends;
