import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/shared/StopLossChart/howtouse";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../../services";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import ADMImage from "../../../assets/img/biweekly_report.png";
import Button from "@atlaskit/button";

import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import LineChart from "../../../components/shared/StopLossChart/lineChart";
import BarChart from "../../../components/shared/StopLossChart/barChart";
import Bi4 from "../../../assets/img/biweekly_report.png";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
];

function StopLoss({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const isFirstRender = useRef(true); 
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");

  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);

  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);

  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);

  const [isDropdownOpenfour, setIsDropdownOpenFour] = useState(false);
  const [dropdownValueFour, setDropdownValueFour] = useState(undefined);

  const [isDropdownOpenfive, setIsDropdownOpenFive] = useState(false);
  const [dropdownValueFive, setDropdownValueFive] = useState(undefined);

  const [isDropdownOpensix, setIsDropdownOpenSix] = useState(false);
  const [dropdownValueSix, setDropdownValueSix] = useState(undefined);

  const [isDropdownOpenseven, setIsDropdownOpenSeven] = useState(false);
  const [avgPercentage, setAvgPercentage] = useState(0);
  const [dropdownValueSeven, setDropdownValueSeven] = useState(undefined);
  const [payPeriodValue, setPayPeriodValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);const [costCenterList, setCostCenterList] = useState([
    {
      label: "100061102 - 4 WEST",
      value: "100061102 - 4 WEST",
    },
  ]);
  const [vpList, setVpList] = useState([]);
  const [executiveList, setExecutiveList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [executiveValue, setExecutiveValue] = useState(null);
  const [vpValue, setVpValue] = useState(null);
  const [directorValue, setDirectorValue] = useState(null);
  const [managerValue, setManagerValue] = useState(null);
  const [payperiodGraph, setPayperiodGraph] = useState([]);
  const [costCenterGraph, setCostCenterGraph] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filterDateList, setFilterDateList] = useState([
    {
      label: "08/10/2024",
      value: "08/10/2024",
    },
  ]);
  const [yearDateList, setYearDateList] = useState([
    {
      label: "2024",
      value: "2024",
    },
  ]);


   const getTableData = async (pyDate, searchKey, cancelToken, costCenter) => {
     setIsTableLoading(true);

     try {
       const paramData = {
         payperiod_end_date: pyDate ? pyDate : payPeriodValue?.value,
         searchKeyword: searchKey,
         businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
         executive: executiveValue?.value,
         director: directorValue?.value, 
         manager: managerValue?.value,
         vp: vpValue?.value,
         cost_center_number: costCenter || null,
       };
       const encryptData = dataEnCrypt(paramData);
       const response = await axios.post(
         `${API_CALL_URL_PRIVATE}/biweeklyReporting/stopLossReporting/getData`,
         { payloadData: encryptData },
         { cancelToken: cancelToken }
       );
       const responseData = decryptedFunction(response?.data?.responseData);
       setTableData(responseData?.data?.tableData);
       const sum = responseData?.data?.tableData.reduce(function (
         previousValue,
         currentValue
       ) {
         return {
           current_period_dollars:
             previousValue.current_period_dollars +
             currentValue.current_period_dollars,
           previous_period_dollars:
             previousValue.previous_period_dollars +
             currentValue.previous_period_dollars,
           rolling4_stoploss:
             previousValue.rolling4_stoploss + currentValue.rolling4_stoploss,
           percentage_performance:
             previousValue.percentage_performance +
             currentValue.percentage_performance,
           // when_posted_days_interval_avg: (
           //   parseFloat(previousValue?.when_posted_days_interval_avg || 0) +
           //   parseFloat(currentValue?.when_posted_days_interval_avg || 0)
           // ).toFixed(0),
         };
       });
       const tableDataLength = responseData?.data?.tableData.length || 0;
       const averagePercentagePerformance =
         tableDataLength > 0 ? sum.percentage_performance / tableDataLength : 0;
       setTotalvalue(sum);
       setAvgPercentage(averagePercentagePerformance);
       setIsTableLoading(false);
     } catch (error) {
       setIsTableLoading(false);
       console.log(error);
     }
   };

  const fetchDataRecursive = async (
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    // try {
      setIsTableLoading(true);

    const parData = {
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      // payperiod_end_date: pyDate ? pyDate : payPeriodValue?.value,
    };

    if (searchKey) {
      parData.searchKeyword = searchKey;
    }

    // else {
    //   parData.businessUnit = "";
    // }
    const encryptData = dataEnCrypt(parData);
    const response = await axios.post(
      `${API_CALL_URL_PRIVATE}/biweeklyReporting/stopLossReporting/getData`,
      { payloadData: encryptData },
      { cancelToken: cancelToken }
    );
    const responseData = decryptedFunction(response?.data?.responseData);
    const costCenters = responseData?.data?.tableData || [];
    setTableData(costCenters);

    // if (searchKey || buisnessCategory || isBuisnessUnitSet !== null) {
    //   setTableData(costCenters);
    // } else {
    //   setTableData((prevData) => [...prevData, ...costCenters]);
    // }

    if (initialCall) {
      setIsTableLoading(false);
    }

    if (response?.data?.data?.hasNextPage) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await fetchDataRecursive(
        response?.data?.data?.nextPage,
        false,
        null,
        null,
        null,
        buisnessCategory
      );
    }
    // } catch (error) {
    //   setIsTableLoading(false);
    //   if (axios.isCancel(error)) {
    //     console.log("Request canceled:", error.message);
    //   } else {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  const getHeadData = async () => {
    setIsLoading(true);

    const parData = {
      cost_center_number: null,
    };

    const encryptData = dataEnCrypt(parData);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/stopLossReporting/getheaderData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCostCenterList = async (ccn) => {
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`
      );
      const data = decryptedFunction(response?.data?.responseData);

      // setCostCenterList(
      //   data?.data?.costCenterResponse.map((item) => {
      //     return {
      //       label: `${item.cost_center_number} - ${capitalizeAllLetters(
      //         item.cost_center_name
      //       )}`,
      //       value: item.cost_center_slug,
      //     };
      //   })
      // );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      return;
    }
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    getFilterDate();
    getMetaData();
    // getTableData();
    // getHeadData();
    // getCostCenterList();
    getRefreshSchedule();
    costCenterGraphData();
    getBuisnesUnit();
    getVpData();
    getExecutiveData();
    getDirectorData();
    getManagerData();
    payPeriodGraphData();
    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);

    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const emptyActiveData = () => {
    getHeadData();
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setUpdateStack(""));
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 103,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchInput = async (key) => {
    //  setIsTableLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      // if (key !== "") {
      //   fetchDataRecursive(page, true, key, 1000, newCancelToken.token);
      // } else {
      //   fetchDataRecursive(page, true, key, null, newCancelToken.token);
      // }

      // const { data } = await axios.post(
      //   `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      //   parData
      // );

      // setTableData(data.data?.cost_centers);

      // if (data?.data?.hasNextPage) {
      //   getTableNextData(data?.data?.nextPage);
      // }
      // setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


    const handleInputChange = (newValue) => {
      setSearchBoxValue(newValue);
      setIsTableLoading(true);

      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      getTableData(
        payPeriodValue?.value,
        newValue,
        newCancelToken.token
      );
    };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }
  }, [isBuisnessUnitSet]);

  useEffect(() => {
    if (mounted) {
      getTableData();
    } else {
      setMounted(true);
    }
  }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 105,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenFour = () => {
    setIsDropdownOpenFour((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenFive = () => {
    setIsDropdownOpenFive((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenSix = () => {
    setIsDropdownOpenSix((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenSeven = () => {
    setIsDropdownOpenSeven((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {}, [isDropdownOpen, dropdownValue]);

  const getFilterDate = async () => {
    setIsTableLoading(true);
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/payPeriodDates`
      );
      const data = decryptedFunction(response?.data?.responseData);
      setFilterDateList(
        data?.data?.payPeriodEndDates.map((item) => {
          return {
            label: item.display_date,
            value: item.payload_date,
          };
        })
      );

      const matchingCostCenter = data?.data?.payPeriodEndDates[0];
      setPayPeriodValue({
        label: matchingCostCenter?.display_date,
        value: matchingCostCenter?.payload_date,
      });

       if(matchingCostCenter) {
         getTableData(matchingCostCenter?.payload_date);
       }
      // return matchingCostCenter;
    } catch (err) {
      console.log(err);
    }
  };

  const getManagerData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/manaagerList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setManagerList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getDirectorData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/directorList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setDirectorList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getVpData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/vpList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setVpList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );

      //  const matchingCostCenter = data?.data?.payPeriodEndDates[0];
    } catch (err) {
      console.log(err);
    }
  };

  const getExecutiveData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/executiveList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setExecutiveList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );

      //  const matchingCostCenter = data?.data?.payPeriodEndDates[0];
    } catch (err) {
      console.log(err);
    }
  };


    const payPeriodGraphData = async (costCenter) => {
      setIsGraphLoading(true);
      const parData = {
        payperiod_end_date: payPeriodValue?.value,
        cost_center_number: costCenter || null,
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
        executive: executiveValue?.value,
        director: directorValue?.value,
        manager: managerValue?.value,
        vp: vpValue?.value,
      };

      const encryptData = dataEnCrypt(parData);

      try {
        const url = `${API_CALL_URL_PRIVATE}/biweeklyReporting/stopLossReporting/payPeriodGraphData`;
        const response = await axios.post(url, {
          payloadData: encryptData,
        });
        const data = decryptedFunction(response?.data?.responseData);

        setPayperiodGraph(data?.data);
        setIsGraphLoading(false);
        // setGraphMaxData(data.data.graph_max_val);
      } catch (error) {
        console.log(error);
      } finally {
        // setIsGraphLoading(false);
      }
    };


    const costCenterGraphData = async (costCenter) => {
      setIsGraphLoading(true);
      const parData = {
        payperiod_end_date: payPeriodValue?.value,
        cost_center_number: costCenter || null,
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
        executive: executiveValue?.value,
        director: directorValue?.value,
        manager: managerValue?.value,
        vp: vpValue?.value,
      };

      const encryptData = dataEnCrypt(parData);

      try {
        const url = `${API_CALL_URL_PRIVATE}/biweeklyReporting/stopLossReporting/costCenterGraphData`;
        const response = await axios.post(url, {
          payloadData: encryptData,
        });
        const data = decryptedFunction(response?.data?.responseData);

        setCostCenterGraph(data?.data);
        setIsGraphLoading(false);
        // setGraphMaxData(data.data.graph_max_val);
      } catch (error) {
        console.log(error);
      } finally {
        // setIsGraphLoading(false);
      }
    };

    console.log("ipnk", costCenterGraph);
    



      useEffect(() => {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }

        if (executiveValue !== null || vpValue !== null ||
          directorValue !== null || managerValue !== null
        ) {
          getTableData();
          payPeriodGraphData();
          costCenterGraphData();
        }
      }, [
        executiveValue,
        vpValue,
        directorValue,
        managerValue,
      ]);
      

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        <Row className="gx-2 mt-0 align-items-center">
          <Col md={{ span: 2 }}>
            <Link className="f-14 white-text-dark-mode" to={`/biweekly`}>
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 8 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              <div className="">
                <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center">
                  <img src={Bi4} alt="" height={38} /> &nbsp; Stop Loss Summary
                  -{" "}
                </h3>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      {payPeriodValue ? payPeriodValue.label : ""}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={filterDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setPayPeriodValue(e);
                      getTableData(e?.value);
                      setSelectedRow(null);
                      // fetchDataRecursive(e?.value, null, null);
                      // getChartFirst(e?.value);
                      // getHeadData(null, null, e?.value);
                      // setSearchBoxValue("");
                      // setGraphDate("");
                    }}
                    value={dropdownValueTwo}
                  />
                </CustomDropdown>
              </div>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center">
              {isLoading ? (
                <HeaderLoaderText themeMode={themeMode} />
              ) : headData.filter_text ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginTop: "10px" }}
                >
                  <div style={{ fontSize: "14px" }}>{headData.filter_text}</div>
                  {headData.show_cross_button ? (
                    <button
                      type="button"
                      class="custom-close-button"
                      onClick={() => {
                        setSearchBoxValue("");
                        // setGraphDate("");
                        // setDropdownValueTwo(undefined);
                        // if (searchBoxValue === "" && graphDate === "") {
                        //   getClearDate();
                        //   getHeadData();

                        //   setGraphData({});
                        //   // setHeadData([]);
                        //   setIsTableLoading(true);
                        // }
                      }}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center pay-period color-red">
              {get(headData, "imp_notes", "")}
            </div> */}
          </Col>
          <Col md={2} className="header-bar h-45p">
            <div className="col">
              <input
                type="text"
                value={searchBoxValue}
                className="form-control py-2 bg-transparent border-light"
                placeholder="Search..."
                onChange={(e) => {
                  handleInputChange(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
        {selectedRow && (
          <div className="d-flex justify-content-center align-items-center mt-1">
            <div style={{ fontSize: "14px" }}>
              {`Filtering data for ${selectedRow?.cost_center_number} - ${selectedRow?.cost_center_name}`}
            </div>
            <button
              type="button"
              class="custom-close-button"
              onClick={() => {
                setSelectedRow(null);
                getTableData();
                payPeriodGraphData();
                costCenterGraphData();
              }}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {/* {!headData.show_cross_button ? (
          ) : (
            ""
          )} */}
          </div>
        )}

        <Row className="gx-2  mb-2 align-items-center">
          <Col md={{ span: 2 }}></Col>
          <Col md={{ span: 8 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              {/* <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      100061102 - 4 WEST
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueFour?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={costCenterList}
                  />
                </CustomDropdown>
              </div> */}
              {/* <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      08/10/2024
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={filterDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setDropdownValueTwo(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueTwo}
                  />
                </CustomDropdown>
              </div> */}

              {/* <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      2024
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    options={yearDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenThree();
                      setDropdownValueThree(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueThree}
                  />
                </CustomDropdown>
              </div> */}
              <div
                className={`custom-select-v ${
                  !isEmpty(executiveValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfive}
                  onClose={toggleOpenFive}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFive}
                      isSelected={isDropdownOpenfive}
                    >
                      {executiveValue ? executiveValue.label : "Executive"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfive}
                    onChange={(e) => {
                      toggleOpenFive();
                      setExecutiveValue(e);
                      // setDropdownValue(e);
                      // getTableData(e?.value, dropdownValueFive?.value);
                    }}
                    options={executiveList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"Executive"}
                  />
                </CustomDropdown>
                {!isEmpty(executiveValue) && (
                  <span
                    onClick={() => {
                      setExecutiveValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(vpValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfour}
                  onClose={toggleOpenFour}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFour}
                      isSelected={isDropdownOpenfour}
                    >
                      {vpValue ? vpValue.label : "VP"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfour}
                    onChange={(e) => {
                      toggleOpenFour();
                      setVpValue(e);
                      // setDropdownValue(e);
                      // getTableData(e?.value, dropdownValueFour?.value);
                    }}
                    options={vpList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"VP"}
                  />
                </CustomDropdown>
                {!isEmpty(vpValue) && (
                  <span
                    onClick={() => {
                      setVpValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(directorValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpensix}
                  onClose={toggleOpenSix}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSix}
                      isSelected={isDropdownOpensix}
                    >
                      {directorValue ? directorValue.label : "Director"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpensix}
                    onChange={(e) => {
                      toggleOpenSix();
                      setDirectorValue(e);
                    }}
                    options={directorList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"Director"}
                  />
                </CustomDropdown>
                {!isEmpty(directorValue) && (
                  <span
                    onClick={() => {
                      setDirectorValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(managerValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenseven}
                  onClose={toggleOpenSeven}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSeven}
                      isSelected={isDropdownOpenseven}
                    >
                      {managerValue ? managerValue.label : "Manager"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenseven}
                    onChange={(e) => {
                      toggleOpenSeven();
                      setManagerValue(e);
                    }}
                    options={managerList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"Manager"}
                  />
                </CustomDropdown>
                {!isEmpty(managerValue) && (
                  <span
                    onClick={() => {
                      setManagerValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col md={2} className="text-end mobile-center mobile-my-2">
            <small
              className="cursor-pointer border-btn f-12  green-b"
              onClick={() => {
                setModalShow(true);
                // activityLogbutton();
              }}
            >
              How to use this page?{" "}
            </small>
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2"></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="gx-2">
          <Col md={6} className="mt-2 h-690x new-height">
            <div className="bg-white p-3 h-100">
              <div class="text-center mb-2">
                <div class="box-heading text-center">
                  Stop Loss Opportunity Report Rolling - 4
                </div>
              </div>

              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table first-t stoploss-table"
              >
                <thead className="">
                  <tr className="vertical-align-middle">
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("cost_center_name")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Cost Center</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "cost_center_number" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("current_period_dollars")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Current
                          <br />
                          Period
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "current_period_dollars" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("previous_period_dollars")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Prior
                          <br />
                          Period
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "previous_period_dollars" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("rolling4_stoploss")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Rolling-4</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "rolling4_stoploss" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("percentage_performance")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Performance
                          <br />
                          Percentage
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "percentage_performance" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody className="ftr">
                  <tr>
                    <td className="text-center">PP24</td>
                    <td className="text-center">7/2/2024</td>
                    <td className=" text-center">17</td>
                    <td className=" text-center">-317.1</td>
                    <td className="text-center">12</td>
                  </tr>
                  <tr>
                    <td className="text-center">PP24</td>
                    <td className="text-center">7/2/2024</td>
                    <td className=" text-center">17</td>
                    <td className=" text-center">-317.1</td>
                    <td className="text-center">12</td>
                  </tr>
                  <tr>
                    <td className="text-center">PP24</td>
                    <td className="text-center">7/2/2024</td>
                    <td className=" text-center">17</td>
                    <td className=" text-center">-317.1</td>
                    <td className="text-center">12</td>
                  </tr>
                </tbody> */}

                <tbody className="">
                  {!isTableLoading ? (
                    tableData?.length > 0 ? (
                      tableData?.map((val, ind) => {
                        return (
                          <tr
                            key={ind}
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                selectedRow?._id === val?._id
                                  ? themeMode.theme === "DARK"
                                    ? "#2c2c2c"
                                    : "rgba(0, 0, 0, 0.075)"
                                  : "inherit",
                            }}
                            onClick={() => {
                              setSelectedRow(val);
                              payPeriodGraphData(val.cost_center_number);
                              costCenterGraphData(val.cost_center_number);
                              getTableData(
                                payPeriodValue?.value,
                                null,
                                null,
                                val.cost_center_number
                              );
                            }}
                          >
                            <td>
                              <div>
                                {val.cost_center_number} -{" "}
                                {val.cost_center_name}
                              </div>
                            </td>
                            <td className="text-center">
                              <div
                              // className={
                              //   val?.overtime_worked_hours <= 4
                              //     ? "text-success"
                              //     : "text-danger"
                              // }
                              >
                                {" "}
                                $
                                {formatNumberWithFraction(
                                  val?.current_period_dollars
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <div
                              // className={
                              //   val?.incidental <= 1
                              //     ? "text-success"
                              //     : "text-danger"
                              // }
                              >
                                {" "}
                                $
                                {formatNumberWithFraction(
                                  val?.previous_period_dollars
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <div>
                                {val?.rolling4_stoploss > 15000 && (
                                  <>
                                    <span className="color-red">X</span>&nbsp;{" "}
                                  </>
                                )}
                                $
                                {formatNumberWithFraction(
                                  val?.rolling4_stoploss,
                                  1
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <div
                                style={{
                                  color:
                                    val?.percentage_performance >= 100
                                      ? "green"
                                      : val?.percentage_performance >= 98 &&
                                        val?.percentage_performance < 100
                                      ? "yellow"
                                      : "red",
                                }}
                              >
                                {" "}
                                {formatNumberWithFraction(
                                  val?.percentage_performance,
                                  1
                                )}
                                %
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                </tbody>

                <tbody className="ftr footer-w">
                  {isTableLoading ? (
                    <div>
                      <TableLoader themeMode={themeMode} />
                    </div>
                  ) : tableData?.length > 0 && !isEmpty(totalvalue) ? (
                    <tr className="bt">
                      <td className="text-center">
                        <strong>Total</strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          $
                          {formatNumberWithFraction(
                            totalvalue?.current_period_dollars,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          $
                          {formatNumberWithFraction(
                            totalvalue?.previous_period_dollars,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          $
                          {formatNumberWithFraction(
                            totalvalue?.rolling4_stoploss,
                            1
                          )}
                        </strong>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color:
                            avgPercentage >= 100
                              ? "green"
                              : avgPercentage >= 98 && avgPercentage < 100
                              ? "yellow"
                              : "red",
                        }}
                      >
                        <strong>
                          {formatNumberWithFraction(avgPercentage, 1)}%
                        </strong>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6} className="mt-2">
            <div className="border-bottom-5 h-half pt-3">
              <div class="text-center mb-2">
                <div class="box-heading text-center">
                  Rolling - 4 Pay Period Stop Loss Opportunity
                </div>
              </div>
              {isGraphLoading ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                <LineChart
                  data={payperiodGraph}
                  // title={"All Cost Center : Actual And Target Worked FTE Trends"}

                  color={"#458FFF"}
                />
              )}
            </div>
            <div className="border-top-5 h-half ">
              <div class="text-center mb-2 mt-3">
                <div class="box-heading text-center">
                  {"Cost Center With Rolling - 4 Opportunity > $15k"}
                </div>
              </div>
              {isGraphLoading ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                <BarChart data={costCenterGraph} color={"#458FFF"} />
              )}
            </div>
          </Col>
          {/* <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </PageLayout>
  );
}

export default StopLoss;
